import PropTypes from 'prop-types'
import React from 'react'
import { graphql, Link, StaticQuery } from 'gatsby'

const Header = props => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(name: {eq: "seaofvoices-logo"}) {
          publicURL
        }
      }
    `}
    render={({ logo }) => (
      <header id='header' style={props.timeout ? { display: 'none' } : {}}>
        <div className='logo'>
          {/* <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' style={{transform: 'rotate(10deg)'}}>
            <path
              // d='m 1.645 28.863 c -1.2645 11.6612 20.2593 7.726 15.167 -7.058 m -2.0125 -4.0188 c 6.0165 -3.5648 3.5437 4.2125 -0.1 5.1687 c -4.808 1.1275 -6.8 -13.4063 3.45 -13.9812 c 15.7125 -0.1937 13.7938 26.625 -9.8075 29.9483 m 12.6345 -5.0658 c 27.0325 0.5282 15.8528 -37.784 4.1718 -22.6465 m 3.1587 -2.8608 c -6.1983 -7.1515 -23.2428 2.384 -17.4617 6.794 m 22.3485 15.078 c 8.2242 -1.5495 9.0588 4.291 13.8858 4.4697 m -11.204 -8.105 c 6.3173 -1.788 7.6283 5.304 13.1112 5.5422'
              // d='M 4.006 34.259 C 1.2235 42.5482 22.7473 38.613 17.655 23.829 M 15.6425 19.8102 C 21.659 16.2454 19.1862 24.0227 15.5425 24.9789 C 10.7345 26.1064 8.7425 11.5726 18.9925 10.9977 C 34.705 10.804 32.7863 37.6227 9.185 40.946 M 21.8195 35.8802 C 48.852 36.4084 37.6723 -1.9038 25.9913 13.2337 M 29.15 10.3729 C 22.9517 3.2214 5.9072 12.7569 11.6883 17.1669 M 34.0368 32.2449 M 36.7186 28.6096 C 43.0359 26.8216 44.3469 33.9136 49.8298 34.1518'
              // d='m 6.535 40.217 c 1.125 0.394 15.6503 0.251 10.558 -14.533 m -2.0125 -4.0188 c 6.0165 -3.5648 3.5437 4.2125 -0.1 5.1687 c -4.808 1.1275 -6.8 -13.4063 3.45 -13.9812 c 15.7125 -0.1937 13.7938 26.625 -7.5675 28.4893 m 10.3945 -3.6068 c 27.0325 0.5282 15.8528 -37.784 4.1718 -22.6465 m 3.1587 -2.8608 c -6.1983 -7.1515 -23.177 -0.0029 -17.4617 6.794 m 24.0597 12.9951 c 5.312 -4.007 9.781 3.991 12.647 0.506 m -4.716 21.404 c 4.093 -3.421 4.892 3.54 9 0.802'
              // d='M 6.535 40.217 c 1.125 0.394 15.6503 0.251 10.558 -14.533 m -2.0125 -4.0188 c 6.0165 -3.5648 3.5437 4.2125 -0.1 5.1687 c -4.808 1.1275 -6.8 -13.4063 3.45 -13.9812 c 15.7125 -0.1937 13.7938 26.625 -7.5675 28.4893 m 10.3945 -3.6068 c 27.0325 0.5282 15.8528 -37.784 4.1718 -22.6465 m 3.1587 -2.8608 c -6.1983 -7.1515 -23.177 -0.0029 -17.4617 6.794 m 24.0597 12.9951 c 5.312 -4.007 9.781 3.991 12.647 0.506 m -18.866 4.116 c 0.684 1.654 5.042 0.437 5.965 -0.481 C 39.554 33.292 43.402 38.636 45.425 36.62'
              d=''
              stroke='white'
              fill='transparent'
              stroke-width='0.5'
            />
          </svg> */}
          <img src='logo.png' srcSet={logo.publicURL} alt='Sea of Voices logo'/>
        </div>
        <div className='content'>
          <div className='inner'>
            <h1 style={{margin: '0'}}>SEA OF</h1>
            <svg xmlns='http://www.w3.org/2000/svg' width='150' height='1'>
                <line x1='0' y1='0' x2='100%' y2='0' stroke='white'/>
            </svg>
            <h1 style={{margin: '0.5rem'}}>VOICES</h1>
            <p>
              Building libraries &amp; tools for Roblox game development
            </p>
          </div>
        </div>
        <nav>
          <ul style={{justifyItems: 'center'}}>
            <li>
              <Link to='projects'>Projects</Link>
            </li>
          </ul>
        </nav>
      </header>
    )}
  />
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
